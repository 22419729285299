import React from "react"
import { Link } from "gatsby"

import NotFound from '../images/404.svg';
import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Nie znaleziono" />
        <div className="flex flex-col items-center container mx-auto" style={{minHeight: '90vh'}}>
            <img src={NotFound} alt="Nie znaleziono strony" className="w-3/4 mt-20" />
            <h1 className="mt-10 font-semibold text-2xl">Nie znaleziono podanej strony!</h1>
            <Link to="/" className="mt-6 btn btn-lg btn-main block w-full sm:inline-block sm:w-auto text-center">Strona główna</Link>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
